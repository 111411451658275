import React from 'react';

import {
  MenuPreloaderBox,
  MenuPreloaderFooter,
  MenuPreloaderLogo,
  MenuPreloaderNavigation,
} from './menu-preloader.styles';

import type { MenuPreloaderProps } from './menu-preloader.types';

export function MenuPreloader({ isDark }: MenuPreloaderProps): JSX.Element {
  return (
    <MenuPreloaderBox $isDark={isDark}>
      <MenuPreloaderLogo>
        <svg width="200" height="8" viewBox="0 0 200 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8H106C108.209 8 110 6.20914 110 4C110 1.79086 108.209 0 106 0H4Z" fill="currentColor" />
        </svg>
      </MenuPreloaderLogo>
      <MenuPreloaderNavigation>
        <svg width="200" height="210" viewBox="0 0 200 210" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V8C0 10.2091 1.79086 12 4 12H106C108.209 12 110 10.2091 110 8V4C110 1.79086 108.209 0 106 0H4ZM4 20C1.79086 20 0 21.7909 0 24V26C0 28.2091 1.79086 30 4 30H156C158.209 30 160 28.2091 160 26V24C160 21.7909 158.209 20 156 20H4ZM192 14C192 11.7909 193.791 10 196 10C198.209 10 200 11.7909 200 14C200 16.2091 198.209 18 196 18C193.791 18 192 16.2091 192 14ZM4 60C1.79086 60 0 61.7909 0 64V68C0 70.2091 1.79086 72 4 72H106C108.209 72 110 70.2091 110 68V64C110 61.7909 108.209 60 106 60H4ZM0 84C0 81.7909 1.79086 80 4 80H156C158.209 80 160 81.7909 160 84V86C160 88.2091 158.209 90 156 90H4C1.79086 90 0 88.2091 0 86V84ZM196 70C193.791 70 192 71.7909 192 74C192 76.2091 193.791 78 196 78C198.209 78 200 76.2091 200 74C200 71.7909 198.209 70 196 70ZM0 124C0 121.791 1.79086 120 4 120H106C108.209 120 110 121.791 110 124V128C110 130.209 108.209 132 106 132H4C1.79086 132 0 130.209 0 128V124ZM4 140C1.79086 140 0 141.791 0 144V146C0 148.209 1.79086 150 4 150H156C158.209 150 160 148.209 160 146V144C160 141.791 158.209 140 156 140H4ZM192 134C192 131.791 193.791 130 196 130C198.209 130 200 131.791 200 134C200 136.209 198.209 138 196 138C193.791 138 192 136.209 192 134ZM4 180C1.79086 180 0 181.791 0 184V188C0 190.209 1.79086 192 4 192H106C108.209 192 110 190.209 110 188V184C110 181.791 108.209 180 106 180H4ZM0 204C0 201.791 1.79086 200 4 200H156C158.209 200 160 201.791 160 204V206C160 208.209 158.209 210 156 210H4C1.79086 210 0 208.209 0 206V204ZM196 190C193.791 190 192 191.791 192 194C192 196.209 193.791 198 196 198C198.209 198 200 196.209 200 194C200 191.791 198.209 190 196 190Z" fill="currentColor" />
        </svg>
      </MenuPreloaderNavigation>
      <MenuPreloaderFooter>
        <svg width="200" height="8" viewBox="0 0 200 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8H106C108.209 8 110 6.20914 110 4C110 1.79086 108.209 0 106 0H4ZM196 0C193.791 0 192 1.79086 192 4C192 6.20914 193.791 8 196 8C198.209 8 200 6.20914 200 4C200 1.79086 198.209 0 196 0Z" fill="currentColor" />
        </svg>
      </MenuPreloaderFooter>
    </MenuPreloaderBox>
  );
}
