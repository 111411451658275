import React from 'react';

import { MenuFallbackBox } from './menu-fallback.styles';
import type { MenuFallbackProps } from './menu-fallback.types';

export function MenuFallback(props: MenuFallbackProps): JSX.Element {
  return (
    <MenuFallbackBox {...props}>
      <div>Menu not worked.</div>
      <div>We are doing our best to fix the issue.</div>
    </MenuFallbackBox>
  );
}
