import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { rpcServiceClient } from '@pushwoosh/grpc-bridge';

import type { TProps } from './appcode-redirect.types';

const {
  ApplicationsServiceClient,
  GetApplicationRequest,
} = rpcServiceClient.applications;

export function AppCodeRedirect(props: TProps): null {
  const { grpcClient } = props;
  const history = useHistory();
  const lastUsedApplication = localStorage.getItem('lastUsedApplication') || '';
  const applicationsGrpcClient = grpcClient.createServiceClient(ApplicationsServiceClient);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!lastUsedApplication) {
        history.replace('/projects');
        return;
      }

      try {
        const req = new GetApplicationRequest();
        req.setCode(lastUsedApplication);
        const res = await applicationsGrpcClient.get(req, null);
        const { application } = res.toObject();

        if (application?.code) {
          const url = window.location.href;
          window.location.href = url.replace(
            '/applications/app-code',
            `/applications/${application.code}`,
          );
          return;
        }

        history.replace('/projects');
      } catch (error: any) {
        history.replace('/projects');
      }
    })();
  }, []);

  return null;
}
