import { ReplaySubject, Subscription } from 'rxjs';

import { RpcClient, RpcFetchBody, RpcResponseSuccess } from '@pushwoosh/http-client';

import type {
  ApplicationData,
  GroupData,
  RouteParams,
  RequestParams,
} from './application-connect.types';

export class ApplicationStore {
  private readonly rpcClient: RpcClient;

  private readonly params: RequestParams;

  private readonly subject: ReplaySubject<ApplicationData | GroupData>;

  private updateData = (data: ApplicationData | GroupData): void => {
    this.subject.next(data);
  };

  constructor(rpcClient: RpcClient, params: RouteParams) {
    this.rpcClient = rpcClient;
    this.params = {
      ...(params.applicationCode && { codes: [params.applicationCode] }),
      ...(params.groupCode && { groupCode: params.groupCode }),
    };
    this.subject = new ReplaySubject<ApplicationData | GroupData>(1);
  }

  public load = async (): Promise<ApplicationData | GroupData> => {
    const { groupCode } = this.params;
    const rpcName = groupCode ? 'appgroups.getByCode' : 'applications.getDetails';
    const rpcRequest = async (url: string, params: RequestParams): Promise<{
      auth: RpcFetchBody['auth'];
      response: RpcResponseSuccess<any>;
    }> => this.rpcClient.callRemoteProcedure(url as any, params);
    const { response } = await rpcRequest(rpcName, this.params);

    if (groupCode) {
      return { ...response.result, isGroup: true };
    }

    const appCode = Object.keys(response.result).length ? Object.keys(response.result)[0] : null;

    if (!appCode) {
      return {};
    }

    return {
      ...response.result[appCode],
      code: appCode,
    };
  };

  public update = async (): Promise<void> => {
    const data = await this.load();
    this.updateData(data);
  };

  public subscribe = (callback: (data: ApplicationData | GroupData) => any): Subscription => (
    this.subject.subscribe(callback)
  );
}
