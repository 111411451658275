import styled, { keyframes } from 'styled-components';

import { Color, Spacing } from '@pushwoosh/kit-constants';

const animation = keyframes`
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.2;
  }
`;

export const NavbarPreloaderBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  height: 64px;
  padding: 0 ${Spacing.S9};

  border-bottom: 1px solid ${Color.DIVIDER};
  color: ${Color.SOFT};

  & svg {
    opacity: 0.1;
    animation: ${animation} 1s ease-in-out infinite;
  }
`;

NavbarPreloaderBox.displayName = 'NavbarPreloaderBox';
