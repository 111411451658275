import styled, { keyframes } from 'styled-components';

export const ApplicationPreloaderBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

const frames = keyframes`
  100% { transform: rotate(1turn); }
`;

export const ApplicationPreloaderAnimation = styled.div`
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #0000;
  border-right-color: grey;
  position: relative;
  animation: ${frames} 1s infinite linear;

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: -8px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }

  &::after {
    animation-duration: 4s;
  }
`;

ApplicationPreloaderBox.displayName = 'ApplicationPreloaderBox';
