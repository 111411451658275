import styled from 'styled-components';

export const ApplicationFallbackBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

ApplicationFallbackBox.displayName = 'ApplicationFallbackBox';
