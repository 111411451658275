import styled, { keyframes } from 'styled-components';
import { opacify } from 'polished';

import { Color, Spacing } from '@pushwoosh/kit-constants';

const animation = keyframes`
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.3;
  }
`;

export const MenuPreloaderBox = styled.div<{ $isDark: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: ${({ $isDark }): string => ($isDark ? '240px' : '260px')};
  height: 100vh;
  background-color: ${({ $isDark }): string => ($isDark ? 'rgba(50, 50, 51, 1)' : Color.CLEAR)};
  box-shadow: 0 4px 6px rgb(0 0 0 / 6%), 0 0 4px rgb(0 0 0 / 6%);

  & svg {
    opacity: 0.1;
    animation: ${animation} 1s ease-in-out infinite;
  }
`;

export const MenuPreloaderLogo = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 72px;
  padding: 0 ${Spacing.S6};
  border-bottom: 1px solid ${opacify(0.1, Color.SOFT_LIGHT)};
`;

export const MenuPreloaderNavigation = styled.div`
  flex: 1 1 auto;

  width: 100%;
  padding: ${Spacing.S5} ${Spacing.S6};

  overflow-y: auto;
`;

export const MenuPreloaderFooter = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 64px;
  padding: 0 ${Spacing.S6};
`;

MenuPreloaderBox.displayName = 'MenuPreloaderBox';
MenuPreloaderLogo.displayName = 'MenuPreloaderLogo';
MenuPreloaderNavigation.displayName = 'MenuPreloaderNavigation';
MenuPreloaderFooter.displayName = 'MenuPreloaderFooter';
