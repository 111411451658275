import styled from 'styled-components';

import { Color } from '@pushwoosh/kit-constants';

export const RootBox = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  min-height: 100vh;
  background-color: ${Color.FROZEN};
`;

export const RootContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1 1 auto;
`;

export const RootInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const RootNavbar = styled.div`
  position: sticky;
  top: 0;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  background: ${Color.CLEAR};
  z-index: 10;

  & + ${RootInner} {
    grid-row-start: 2;
  }
`;

export const RootMenu = styled.div`
  position: relative;
  grid-row-start: 2;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 2;
  z-index: 20;

  & + ${/* sc-selector */RootNavbar}, & + ${/* sc-selector */RootInner}, & + ${/* sc-selector */RootNavbar} + ${/* sc-selector */RootInner} {
    grid-column-start: 2;
  }
`;

export const RootMenuTopRound = styled.div`
  position: absolute;
  top: 0;
  right: -16px;
  width: 16px;
  height: 16px;
  background: ${Color.MAIN};
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    background: ${Color.CLEAR};
    border-radius: 50%;
  }
`;

export const RootMenuInner = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  z-index: 200;
`;

RootBox.displayName = 'RootBox';
RootContent.displayName = 'RootContent';
RootNavbar.displayName = 'RootNavbar';
RootMenu.displayName = 'RootMenu';
RootMenuTopRound.displayName = 'RootMenuTopRound';
RootMenuInner.displayName = 'RootMenuInner';
