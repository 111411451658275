import React, { useCallback, useLayoutEffect } from 'react';
import * as singleSpa from 'single-spa';

import {
  ApplicationBox,
  ApplicationContainer,
  ApplicationPreloader,
} from './application.styles';

import type { ApplicationProps } from './application.types';

export function Application(props: ApplicationProps): JSX.Element {
  const {
    name,
    isBroken,
    customProps,
    preloader,
    fallback,
  } = props;

  const registerApp = useCallback(
    () => singleSpa.registerApplication(
      name,
      () => System.import<singleSpa.LifeCycles>(name),
      () => true,
      customProps,
    ),
    [name],
  );

  useLayoutEffect(() => {
    const isAlreadyRegistered = !!singleSpa.getMountedApps().find((mountedName) => mountedName === name);

    isAlreadyRegistered
      ? singleSpa.unregisterApplication(name).then(() => registerApp())
      : registerApp();

    return (): void => {
      singleSpa.unregisterApplication(name);
    };
  }, [name]);

  if (isBroken) {
    return (
      <ApplicationBox>
        {fallback}
      </ApplicationBox>
    );
  }

  return (
    <ApplicationBox>
      <ApplicationContainer id={`single-spa-application:${name}`} />
      <ApplicationPreloader>
        {preloader}
      </ApplicationPreloader>
    </ApplicationBox>
  );
}
