import React from 'react';

import { Link } from '@pushwoosh/kit-link';

import { ApplicationFallbackBox } from './application-fallback.styles';
import type { ApplicationFallbackProps } from './application-fallback.types';

export function ApplicationFallback(props: ApplicationFallbackProps): JSX.Element {
  return (
    <ApplicationFallbackBox {...props}>
      <div>Something wrong.</div>
      <div>We are doing our best to fix the issue.</div>
      <div>
        You can try
        {' '}
        <Link
          href="#"
          title="reload the page"
          onClick={(): void => window.location.reload()}
        >
          reload the page.
        </Link>
      </div>
    </ApplicationFallbackBox>
  );
}
