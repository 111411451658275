import React from 'react';

import { ApplicationPreloaderBox, ApplicationPreloaderAnimation } from './application-preloader.styles';
import type { ApplicationPreloaderProps } from './application-preloader.types';

export function ApplicationPreloader(props: ApplicationPreloaderProps): JSX.Element {
  return (
    <ApplicationPreloaderBox {...props}>
      <ApplicationPreloaderAnimation />
    </ApplicationPreloaderBox>
  );
}
