import styled from 'styled-components';

import { Color, Spacing } from '@pushwoosh/kit-constants';

export const NavbarFallbackBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  height: 64px;
  padding: 0 ${Spacing.S9};

  border-bottom: 1px solid ${Color.DIVIDER};
`;

NavbarFallbackBox.displayName = 'NavbarFallbackBox';
