import React from 'react';

import { NavbarPreloaderBox } from './navbar-preloader.styles';
import type { NavbarPreloaderProps } from './navbar-preloader.types';

export function NavbarPreloader(props: NavbarPreloaderProps): JSX.Element {
  return (
    <NavbarPreloaderBox {...props}>
      <svg width="114" height="22" viewBox="0 0 114 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0ZM40 5C36.6863 5 34 7.68629 34 11C34 14.3137 36.6863 17 40 17H108C111.314 17 114 14.3137 114 11C114 7.68629 111.314 5 108 5H40Z" fill="currentColor" />
      </svg>
      <svg width="154" height="36" viewBox="0 0 154 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.1402 12.5215C19.5783 12.1853 19.6644 11.5535 19.2861 11.1511C18.077 9.86478 16.5442 8.91691 14.8401 8.41214C12.8153 7.81236 10.6526 7.86899 8.6619 8.57392C6.67124 9.27885 4.95484 10.5959 3.75871 12.3363C2.56258 14.0767 1.94812 16.151 2.0034 18.2621C2.05868 20.3732 2.78086 22.4126 4.06644 24.088C5.35202 25.7633 7.13499 26.9888 9.15982 27.5885C11.1846 28.1883 13.3474 28.1317 15.338 27.4268C17.0134 26.8335 18.4944 25.8067 19.6346 24.4589C19.9913 24.0372 19.8723 23.4108 19.4171 23.098L13.1343 18.7799C12.5744 18.3951 12.553 17.576 13.0919 17.1624L19.1402 12.5215Z" fill="currentColor" />
        <rect width="6" height="6" rx="2" transform="matrix(1 0 0 -1 36 21)" fill="currentColor" />
        <rect width="6" height="6" rx="2" transform="matrix(1 0 0 -1 62 21)" fill="currentColor" />
        <rect width="6" height="6" rx="2" transform="matrix(1 0 0 -1 88 21)" fill="currentColor" />
        <rect width="6" height="6" rx="2" transform="matrix(1 0 0 -1 114 21)" fill="currentColor" />
        <rect width="6" height="6" rx="2" transform="matrix(1 0 0 -1 140 21)" fill="currentColor" />
      </svg>
    </NavbarPreloaderBox>
  );
}
