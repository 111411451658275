import { Account, User } from '@pushwoosh/session-store';
import Cookies from 'js-cookie';
import mixpanel from 'mixpanel-browser';

type Event = {
  readonly name: string;
  readonly properties?: Record<string, unknown>;
};

const isProductionMode = process.env['NODE_ENV'] === 'production';

const projectToken = '4169a4e38679ed49785b430a1049238e';

export function initMixpanelSession(): void {
  if (!isProductionMode) {
    return;
  }

  mixpanel.init(projectToken, {
    debug: true,
  });
}

function getMixpanelDistinctId(): string | null {
  const mixpanelCookie = Cookies.get(`mp_${projectToken}_mixpanel`);
  if (mixpanelCookie) {
    try {
      const mixpanelData = JSON.parse(mixpanelCookie);
      return mixpanelData.distinct_id;
    } catch (error) {
      console.error('Error parsing Mixpanel cookie:', error);
    }
  }
  return null;
}

/**
 * Идентифицировать текущую сессию пользователя
 * Выполняется только в боевом режиме и если пользователь не администратор!
 * @param user - пользователь
 * @param account - аккаунт
 */
export function identifyMixpanelSession(user: User, account: Account): void {
  if (!isProductionMode) {
    return;
  }
  const distinctId = getMixpanelDistinctId();
  const wasPushwooshUser = distinctId?.endsWith('@pushwoosh.com');
  const isCurrentPushwooshUser = user.email.endsWith('@pushwoosh.com');

  if (!wasPushwooshUser || isCurrentPushwooshUser) {
    mixpanel.identify(user.email);

    mixpanel.people.set({
      $email: user.email,
      $name: user.name,
      AccountID: account.id,
      Restriction: account.productName,
    });
  }
}

export function trackMixpanelEvent(event: Event): void {
  if (!isProductionMode) {
    return;
  }

  mixpanel.track(event.name, event.properties);
}
