import React from 'react';

import { NavbarFallbackBox } from './navbar-fallback.styles';
import type { NavbarFallbackProps } from './navbar-fallback.types';

export function NavbarFallback(props: NavbarFallbackProps): JSX.Element {
  return (
    <NavbarFallbackBox {...props}>
      <div>Navigation Bar not worked.</div>
      <div>We are doing our best to fix the issue.</div>
    </NavbarFallbackBox>
  );
}
