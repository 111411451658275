import styled from 'styled-components';

export const ApplicationBox = styled.div`
  width: 100%;
  height: 100%;
`;

export const ApplicationContainer = styled.div``;

export const ApplicationPreloader = styled.div`
  display: none;

  ${/* sc-selector */ApplicationContainer}:empty + & {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

ApplicationBox.displayName = 'ApplicationBox';
ApplicationContainer.displayName = 'ApplicationContainer';
ApplicationPreloader.displayName = 'ApplicationPreloader';
