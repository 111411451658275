import React from 'react';
import { useParams } from 'react-router-dom';

import { ApplicationWithWidget } from '../application/application.withWidget';
import { ApplicationStore } from './application-store';
import type { ApplicationWithDataProps } from './application-connect.types';

export function ApplicationConnect(props: ApplicationWithDataProps): JSX.Element {
  const { customProps, customProps: { rpcClient } } = props;
  const params = useParams();
  const additionalCustomProps: { applicationStore?: ApplicationStore } = {};

  if (rpcClient && JSON.stringify(params) !== '{}') {
    const applicationStore = new ApplicationStore(rpcClient, params);
    applicationStore.update();
    additionalCustomProps.applicationStore = applicationStore;
  }

  return (
    <ApplicationWithWidget {...props} customProps={{ ...customProps, ...additionalCustomProps }} />
  );
}
