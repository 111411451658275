import React, { useEffect, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { setApplicationCode } from '../../helpers/app.helpers';
import { ApplicationPreloader } from '../../preloaders/application-preloader';
import { ApplicationFallback } from '../../fallbacks/application-fallback';
import { Application } from './application.component';

import type { ApplicationWithBadgesProps } from './application.types';

function ApplicationWithWidgetAndRouter({
  widgetState,
  name,
  isBroken,
  customProps,
  location,
  match,
}: ApplicationWithBadgesProps & (RouteComponentProps | null)): ReactElement {
  if (window.zE) {
    window.zE('webWidget', widgetState);
  }

  useEffect(() => {
    if (location.pathname) {
      setApplicationCode(location.pathname);
    }
  }, [location.pathname]);

  return (
    <Application
      name={name}
      isBroken={isBroken}
      customProps={{
        routerParams: {
          location,
          match,
        },
        ...customProps,
      }}
      preloader={(<ApplicationPreloader />)}
      fallback={(<ApplicationFallback />)}
    />
  );
}

export const ApplicationWithWidget = withRouter(ApplicationWithWidgetAndRouter);
