import styled from 'styled-components';

import { Color, Spacing } from '@pushwoosh/kit-constants';

export const MenuFallbackBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 240px;
  padding: 0 ${Spacing.S6};

  color: ${Color.CLEAR};
  text-align: center;
`;

MenuFallbackBox.displayName = 'MenuFallbackBox';
